<template>
  <section class="app-ecommerce-details">
    <b-row class="content-header">
      <!-- Content Left -->
      <b-col class="content-header-left mb-2" cols="12" md="9">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              تفاصيل المنتج
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: 'front.Home' }">
                  المنتجات
                </b-breadcrumb-item>

                <b-breadcrumb-item :active="true">
                  تفاصيل المنتج
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="product === undefined">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link class="alert-link" :to="{ name: 'front.Home' }"> Shop </b-link>
        for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <b-card v-if="product" no-body>
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="product.image"
                :alt="`Image of ${product.name}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>{{ product.name }}</h4>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <span>البراند</span>
              <b-link class="company-name">
                {{ product.brand_name }}
              </b-link>
            </b-card-text>

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">{{ product.price }}</h4>
              <ul class="unstyled-list list-inline pl-1 border-left">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item mr-25"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[
                      { 'fill-current': star <= product.rating },
                      star <= product.rating ? 'text-warning' : 'text-muted',
                    ]"
                  />
                </li>
              </ul>
            </div>

            <!-- Avability -->
            <b-card-text
              >الكمية المتاحة :
              <span class="text-success">{{
                product.available
              }}</span></b-card-text
            >

            <!-- Product Description -->
            <!-- <b-card-text v-html="product.description"></b-card-text> -->

            <!-- Product Meta [Free shpping, EMI, etc.] -->
            <!-- <ul class="product-features list-unstyled">
             
              <li>
                <feather-icon icon="DollarSignIcon" />
                <span>$</span>
              </li>
            </ul> -->

            <hr />

            <!-- Colors -->
            <!-- <div class="product-color-options">
              <h6>Colors</h6>
              <ul class="list-unstyled mb-0">
                <li
                  v-for="color in product.colorOptions"
                  :key="color"
                  class="d-inline-block"
                  :class="{ selected: selectedColor === color }"
                  @click="selectedColor = color"
                >
                  <div class="color-option" :class="`b-${color}`">
                    <div class="filloption" :class="`bg-${color}`" />
                  </div>
                </li>
              </ul>
            </div> -->
            <b-table-simple hover bordered responsive class="rounded-bottom">
              <b-tr>
                <b-th> الكمية المتوفرة</b-th>
                <b-th
                  v-for="item in product.items"
                  :key="`to_qty_to_qty${item.id}`"
                  >{{ item.from_qty }} - {{ item.to_qty }}</b-th
                >
                <!-- <b-th>التقييمات </b-th> -->
              </b-tr>

              <b-tr>
                <!-- <b-td>♾️</b-td> -->
                <b-td>{{ product.available }}</b-td>
                <b-td
                  v-for="item in product.items"
                  :key="`price_format${item.id}`"
                >
                  {{ item.price_format }}
                </b-td>
              </b-tr>
            </b-table-simple>
            <hr />
            <div class="item-quantity">
              <span class="quantity-title">الكمية:</span>
              <b-form-spinbutton
                v-model="product.quantity"
                class="ml-75"
                inline
                style="width: 200px"
              />
            </div>
            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                v-if="!isProductInCart(product.uuid)"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="handleCartActionClick(product)"
              >
                <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                <span>{{
                  product.isInCart ? "View In Cart" : "Add to Cart"
                }}</span>
              </b-button>
              <b-button
                v-if="isProductInCart(product.uuid)"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="$router.push({ name: 'cataloge-checkout' })"
              >
                <feather-icon icon="ShoppingCartIcon" class="mr-50" />
              </b-button>

              <b-button
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="toggleProductInWishlist(product)"
              >
                <feather-icon
                  icon="HeartIcon"
                  class="mr-50"
                  :class="{ 'text-danger': product.isInWishlist }"
                />
                <span>Wishlist</span>
              </b-button>
              <b-dropdown
                variant="outline-secondary"
                no-caret
                toggle-class="btn-icon"
                class="btn-share"
                right
              >
                <template #button-content>
                  <feather-icon icon="Share2Icon" />
                </template>
                <b-dropdown-item
                  v-for="icon in [
                    'FacebookIcon',
                    'TwitterIcon',
                    'YoutubeIcon',
                    'InstagramIcon',
                  ]"
                  :key="icon"
                >
                  <feather-icon :icon="icon" />
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Static Content -->
      <!-- <item-features /> 
      <related-products /> -->
    </b-card>
  </section>
</template>

<script>
// {"id":27,"name":"Apple Watch Series 5","slug":"apple-watch-series-5-27","brand":"Apple","price":339.99,"image":"/img/1.3b312012.png","hasFreeShipping":true,"rating":4,"description":"On Retina display that never sleeps, so it’s easy to see the time and other important information, without\n    raising or tapping the display. New location features, from a built-in compass to current elevation, help users\n    better navigate their day, while international emergency calling1 allows customers to call emergency services\n    directly from Apple Watch in over 150 countries, even without iPhone nearby. Apple Watch Series 5 is available\n    in a wider range of materials, including aluminium, stainless steel, ceramic and an all-new titanium.","isInWishlist":false,"isInCart":true,"colorOptions":["primary","success","warning","danger","info"]}
import { useRouter } from "@core/utils/utils";
import store from "@/store";
import { ref } from "@vue/composition-api";
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ItemFeatures from "./ItemFeatures.vue";
import RelatedProducts from "./RelatedProducts.vue";
import { useEcommerceUi, isProductInCart } from "../useCataloge";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,

    // SFC
    ItemFeatures,
    RelatedProducts,
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();

    const product = ref(null);

    // Remote Data
    const fetchProduct = () => {
      // Get product  id from URL
      const { route } = useRouter();
      const productId = route.value.params.slug;
      // const productId = productSlug.substring(productSlug.lastIndexOf("-") + 1);

      store
        .dispatch("cataloge/fetchProduct", { productId })
        .then((response) => {
          product.value = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            product.value = undefined;
          }
        });
    };

    // UI
    const selectedColor = ref(null);

    fetchProduct();

    return {
      // Fetched Product
      product,

      // UI
      selectedColor,
      handleCartActionClick,
      toggleProductInWishlist,
      isProductInCart,
    };
  },
};
</script>
<style scoped>
.b-form-spinbutton output > div,
.b-form-spinbutton output > bdi {
  font-size: 20px;
}
</style>
<style lang="scss">
@import "~@resources/scss/base/pages/app-ecommerce-details.scss";
</style>
